<template>

  <div>
    <div class="row row-fluid justify-content-between align-items-center action-bar">
      <div class="col-md-2">
        <b-icon-search class="inner"></b-icon-search><input class="form control-form busqueda" placeholder="Search user" v-model="searchtext" @change="getusers">
      </div>
      <div class="col-md-2 d-flex align-items-end justify-content-end">
        <b-button class="btn btn-primary btndash" id="toggle-btn" @click="toggleModal">Create user</b-button>
      </div>
    </div>
    
    <div class="container contentwh">      
        
        <div class="py-3 pl-6 pr-6">
            <div class="table-responsive">
                <table class="table table-borderless table-vertical-center listados">
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="mb-3" v-for="user in arrayUsers" :key="user.id">
                            <td class="pl-0">
                                <router-link
                                    :to="{ path: '/user/' + user.id }"
                                    class="link-dash-gps"
                                >
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="user.email"
                                ></span>
                                </router-link>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-if="user.role==0"
                                >Admin</span>
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-if="user.role==1"
                                >Origin</span>
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-if="user.role==2"
                                >Destination</span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                >{{moment(user.createdAt).format('DD/MM/YYYY')}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>

    <b-modal ref="createUser" title="Create user" 
      @show="resetModalcreateUser"
      @hidden="resetModalcreateUser"
      @ok="handleOkcreateUser">      
        <form ref="form" @submit.stop.prevent="handleSubmitcreateUser">
            <div class="alert alert-danger" role="alert" v-if="error !== null">
                {{ error }}
            </div>
            <div class="row-fluid row align-items-center">
                <div class="col-md-12">
                    <label class="form-group">Email</label>
                    <input id="email" type="email" class="form-control" v-model="email" required autofocus autocomplete="off" placeholder="Email">
                </div>
            </div>
            <div class="row-fluid row align-items-center">
                <div class="col-md-12">
                    <label class="form-group">Password</label>
                    <input id="password" type="password" class="form-control" v-model="password" required autocomplete="off" placeholder="Password">
                </div>
            </div>
            <div class="row-fluid row align-items-center">
                <div class="col-md-12">
                    <label class="form-group">Repeat Password</label>
                    <input id="repeatpassword" type="password" class="form-control" v-model="repeatpassword" required autocomplete="off" placeholder="Password">
                </div>
            </div>
            <div class="row-fluid row align-items-center">
                <div class="col-md-12">
                    <label class="form-group">Role</label>
                    <select id="role" class="form-control" v-model="role" required placeholder="Role">
                        <option v-bind:value="0">Admin</option>
                        <option v-bind:value="1">Origin</option>
                        <option v-bind:value="2">Destination</option>
                        <option v-bind:value="3">Korea</option>
                    </select>
                    <!--input id="role" type="text" class="form-control" v-model="role" required autocomplete="off" placeholder="Role"-->
                </div>
            </div>            
        </form>
    </b-modal>

  </div>
</template>

<script>    
    var moment = require("moment");
    import axios from 'axios';
    export default { 
        data(){
            return{                
                arrayUsers:[], //Este array contendrá las tareas de nuestra bd                
                page: 1,
                total:0,
                porpag:5,
                email: "",
                password: "",
                repeatpassword: "",
                role:0,
                emailState: null,
                passwordState: null,
                roleState: null,
                error: null, 
                searchtext:null,
            }
        },
        methods:{
            getusers(data){
                let me =this;                
                let url = process.env.VUE_APP_BACK+'/api/users';//+me.page+'/'+me.porpag+'/'+me.searchtext;
                if(this.searchtext=='' || this.searchtext == null){
                    this.searchtext=null;
                    axios.get(url).then(function (response) {
                        //me.total = response.data.total
                        me.arrayUsers = response.data;                
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    });
                }
                else{
                    axios.get(url, { params: {
                            searchtext: this.searchtext
                    }})
                    .then((response) => {                                                       
                        me.arrayUsers = response.data;  
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
                }                            
            },
            toggleModal() {
                this.$refs['createUser'].toggle('#toggle-btn')
              },
            checkFormValidity() {
                const valid = this.$refs.form.checkValidity()
                this.emailState = valid
                this.passwordState = valid
                this.roleState = valid
                if (valid)
                    return valid
                else {
                    if (this.repeatpassword != this.password){
                        this.error = "Password doesn't match"
                    }
                    else{                        
                        this.error = "Error. Check every field please"
                    }
                }
            },
            resetModalcreateUser() {
                this.email = ''
                this.password = ''
                this.role = 0
                this.repeatpassword = ''
                this.error = null
                this.emailState = null
                this.passwordState = null
                this.roleState = null
            },
            handleOkcreateUser(bvModalEvent) {
                // Prevent modal from closing
                bvModalEvent.preventDefault()
                // Trigger submit handler
                this.handleSubmitcreateUser()
            },
            handleSubmitcreateUser() {
                // Exit when the form isn't valid
                if (!this.checkFormValidity()) {
                  return
                }
                axios
                    .post(process.env.VUE_APP_BACK+"/api/users", {
                        email: this.email,
                        role: this.role,
                        validated: 1,//this.update,
                        password: this.password,
                    })
                    .then((response) => {
                        /*if (response.data.success) {
                            $("#newCategory").modal("toggle");
                            me.error = null;
                            me.getCategories();
                        } else {
                            me.error = response.data.message;
                        }*/
                        this.resetModalcreateUser()
                        this.$refs['createUser'].toggle('#toggle-btn')
                        this.getusers()
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
                this.$nextTick(() => {
                  this.$bvModal.hide('modal-prevent-closing')                  
                })
            },
            moment
        },
        mounted() {            
           this.getusers()
        }
    }
</script>
