    <template>

  <div>
    <div class="row row-fluid justify-content-between align-items-center action-bar">
      <div class="col-md-2">
        <router-link
            :to="{ path: '/'}"
            class="btn-back btnback"
                    ><
		</router-link>                    
      </div>
    </div>

    <ul class="tab-gps">
		<li @click="showData" class="active">Data</li>
		<li @click="showImages">Images</li>
	</ul>

    <div class="container contentwh datatab">      
        
        <div class="py-3 pl-6 pr-6">        	

        	<div class="row row-fluid justify-content-between action-bar">
      			<div class="col-md-2 qr-size brdata">      				
                    <div><img :src="qr"></div>
      			</div>
      			<div class="col-md-5 brdata">
      				<div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-12 titledata">
                            <span style="color: #E54100; font-size: 1.2em; font-weight: bolder; margin-bottom:20px; border-bottom:1px dotted black">LOCATION:</span>
                        </div>                        
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Inspector Name:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.user_name}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            GMT Date&Time:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.date_gmt}} {{arrayGpss.time_gmt}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Country:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.country}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            City:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.city}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Location:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.location}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Inspection Type:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.inspection_type}}
                        </div>
                    </div>

                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-12 titledata">
                            <span style="color: #E54100; font-size: 1.2em; font-weight: bolder; margin-bottom:20px; border-bottom:1px dotted black"><br>TRUCK:</span>
                        </div>                        
                    </div>                    
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            OP ID:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.id_charger}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Receiver:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.receiver}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Truck Plate:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.truck_plate}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Truck ID:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.truck_id}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Container Booking Ref:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.cont_booking}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            N Units:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.n_units}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Unit 1:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.trailler_1_plate}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Unit 2:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.trailer_2_plate}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            (WEIGHED) TRUCK GROSS:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.gross}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            (WEIGHED) TRUCK TARE:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.tare}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            (WEIGHED) TRUCK NET:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.net}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            TRUCK WEIGHT DIFFERENCE:
                        </div>
                        <div class="col-md-6 contentdata">
                            
                        </div>
                    </div>

                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-12 titledata">
                            <span style="color: #E54100; font-size: 1.2em; font-weight: bolder; margin-bottom:20px; border-bottom:1px dotted black"><br>UNIT:</span>
                        </div>                        
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Actual:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.actual}}
                        </div>
                    </div>                    
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            UNIT 1 BAGS:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.unit_1_bags}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            UNIT 2 BAGS:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.unit_2_bags}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            NR BAGS Reg:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.n_bags}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            (LABEL) UNIT GROSS:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.label_unit_gross}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            (LABEL) UNIT NET:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{parseFloat(arrayGpss.label_unit_net).toFixed(2)}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            (WEIGHED) UNIT GROSS:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.weighed_tot_obj_gross}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            (WEIGHED) UNIT NET:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{parseFloat(arrayGpss.weighed_tot_obj_net).toFixed(2)}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            UNIT WEIGHT DIFFERENCE:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{parseFloat(arrayGpss.unit_w_diff).toFixed(2)}}
                        </div>
                    </div>                    
                </div>
                <div class="col-md-5">   
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-12 titledata">
                            <span style="color: #E54100; font-size: 1.2em; font-weight: bolder; margin-bottom:20px; border-bottom:1px dotted black"><br>BAG:</span>
                        </div>                        
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Bag Number:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.bag_number}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Lot Number:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.lot_number}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Client Seal QR:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.kam_seal_nr}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Client Seal Recheck:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.kam_seal_rechknr}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            3RD PARTY Seal:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.thirdparty_seal}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            3rd Party PL Match:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.thirdparty_packing_match}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            3rd Party Packing Seal Nr:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.third_p_seal}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            (LABEL) BAG GROSS:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.kam_grossw}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            (LABEL) BAG TARE:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.kam_bagw}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            (LABEL) BAG NET:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.kam_netw}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            (WEIGHED) BAG GROSS:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.war_grossw}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            (WEIGHED) BAG NET:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.war_netw}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            BAG WEIGHT DIFFERENCE:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{parseFloat(arrayGpss.bag_w_diff).toFixed(2)}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Weight Issue:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.weight_issue_}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            RADIATION LEVEL:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.radiation}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Color of Stiching Thread:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.color_stich_thr}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Bag Inspected:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.inspected}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Bag CONDITION:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.bag_condition}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Seal CONDITION:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.seal_condition}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Cargo CONDITION:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.cargo_condition}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Segregation:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.segregation}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Re-Seal Nr:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.reseal_nr}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Observations:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{arrayGpss.observations}}
                        </div>
                    </div>
      			</div>
                <div class="col-md-2">
                </div>
      		</div>

        </div>

    </div>

    <div class="container contentwh imagestab">
    	<div class="py-3 pl-6 pr-6">        	

        	<div class="row row-fluid justify-content-evenly align-items-center action-bar images" v-viewer>
      			<div class="col-md-3 image-gps" v-for="image in arrayImages" style="overflow: hidden; margin-bottom:30px" >
                    <div class="overlay" :src="image"  @click="show(image)">
                        <b-icon-zoom-in class="fillwhite"></b-icon-zoom-in>
                    </div>
                    <img :src="image" style="width: 278px; height: 350px">                    
      				<a :href="image" target="_blank" class="download"><b-icon-download class="fillorange"></b-icon-download></a>
      			</div>
      		</div>

      	</div>
    </div>

  </div>

</template>

<script>
	import axios from 'axios';
    import Vue from 'vue';
    import 'viewerjs/dist/viewer.css'
    import VueViewer from 'v-viewer'  
    Vue.use(VueViewer)

    export default { 
        data(){
            return{                
                arrayGpss:[], //Este array contendrá las tareas de nuestra bd                
                arrayImages:[],
                qr:null,
                page: 1,
                total:0,
                porpag:5,
                searchtext:null,
                gpsId:null,
            }
        },
        methods:{
              show(image) {
                this.$viewerApi({
                  images: [image],
                  options:{ 
                    "inline": true, 
                    "button": true,                 
                    "navbar": false, 
                    "title": false, 
                    "toolbar": false, 
                    "tooltip": true, 
                    "movable": false, 
                    "zoomable": true, 
                    "rotatable": false, 
                    "scalable": true, 
                    "transition": true, 
                    "fullscreen": true, 
                    "keyboard": false}
                })
              },
            getGps(data){                
                let me =this;
                let url = process.env.VUE_APP_BACK+'/api/gpss/'+me.gpsId;//+me.page+'/'+me.porpag+'/'+me.searchtext;
                axios.get(url).then(function (response) {
                    //me.total = response.data.total
                    //console.log(response.data.images);
                    me.qr = me.arrayImages = response.data.qr;
                    me.arrayImages = response.data.images;
                    me.arrayGpss = response.data.data;                
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                });
            },
            showImages(){
            	jQuery(".imagestab").toggle();
            	jQuery(".datatab").toggle();
            	jQuery(".tab-gps li").toggleClass('active');
            },
            showData(){
            	jQuery(".imagestab").toggle();
            	jQuery(".datatab").toggle();
            	jQuery(".tab-gps li").toggleClass('active');
            }
        },
        mounted() {                	
           this.gpsId = this.$route.params.id;    
           this.getGps(this.$route.params.id);
        }
    }
</script>