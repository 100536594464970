<template>
  <div class="fullheight">
    <div class="headerorange">
      <div class="logo-sgs"><img alt="Logo" src="/logo-sgs.png" width="200" /></div>
    </div>
    <div class="pantallalogin login">    
      <div class="formlogin">
        <form action class="form" @submit.prevent="login">
          <h1 class="title">Sign in</h1>
          <p style="text-align:center">Enter your email and password</p>
          <label class="form-label" for="#email">Email</label>
          <input
            v-model="email"
            class="form-input"
            type="email"
            id="email"
            required
            placeholder="Email"
          >
          <label class="form-label" for="#password">Password</label>
          <input
            v-model="password"
            class="form-input"
            type="password"
            id="password"
            placeholder="Password"
          >
          <div class="form-label row-fluid row align-items-center justify-content-between" style="margin-top:40px">
              <div class="col-md-6">
                <label class="containerremember">
                <input
                  v-model="remember"
                  class="form-input"
                  type="checkbox"
                  id="remember"
                ><span class="checkmark"></span></label> Remember me
              </div>
              <div class="col-md-6">
                <router-link to="/recover" class="forgot">Forgot password?</router-link>
              </div>
          </div>
          <p v-if="error" class="error">Email or password incorrect.</p>
          <input class="form-submit" type="submit" value="Sign In">
        </form>
      </div>
    </div>
    <div class="footer-login justify-content-center">
        <div class="row-fluid row align-items-center justify-content-center">
            <div class="col-md-12">
                <p style="text-align:center">&copy; 2022 SGS | BAGS</p>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import auth from "@/logic/auth";
export default {
  data: () => ({
    email: "",
    password: "",
    remember:null,
    error: false
  }),
  beforeCreate(){
      console.log("Deslogeado");
      auth.deleteUserLogged();
    },
  methods: {    
    async login() {
      try {
        await auth.login(this.email, this.password);
        const user = {
          email: this.email
        };
        console.log('EMAIL USER: '+user.email);
        auth.setUserLogged(user.email);
        this.$router.push("/");
      } catch (error) {
        console.log(error);
        this.error = true;
      }
    }
  }
};
</script>

