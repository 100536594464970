<template>
    
  <div>
    <div class="row row-fluid justify-content-between align-items-center action-bar">
      <div class="col-md-10">  
        &nbsp;
      </div>
      <div class="col-md-2 d-flex align-items-end justify-content-end">
        <div class="mostrardesplegable">
            <button class="btn btn-primary btndash">Export</button>
            <div class="desplegable">
                <span class="item" @click="getCSVZip"><b-icon-file-text class="fillorange"></b-icon-file-text> CSV</span>
                <span class="item" @click="getImagesZip"><b-icon-image class="fillorange"></b-icon-image> Images</span>
                <span class="item" @click="getAllZip"><b-icon-stack class="fillorange"></b-icon-stack> All</span>
            </div>
        </div>
      </div>
    </div>
    <div class="row row-fluid justify-content-between align-items-center action-bar">
      <div class="col-md-2">
        <b-icon-search class="inner"></b-icon-search><input class="form control-form busqueda" placeholder="Enter your search" v-model="searchtext" @change="getGpss">        
      </div>
      <div class="col-md-2">        
        <b-icon-search class="inner"></b-icon-search><input class="form control-form busqueda" placeholder="By Lot Number" v-model="lotnumbersearch" @change="getGpss">
      </div>
      <div class="col-md-2">        
        <b-icon-search class="inner"></b-icon-search><input class="form control-form busqueda" placeholder="Customer" v-model="customerfilter" @change="getGpss">
      </div>
      <div class="col-md-6 d-flex align-items-start justify-content-start">
          <div class="row">
              <div class="col-md-2">
                <div class="filtrado">
                    <select 
                        v-model="countryfilter"
                        @change="getGpss"
                    >
                        <option v-bind:value="0">Country</option>
                        <option v-for="country in countries" v-bind:value="country">{{ country }}</option>
                    </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="filtrado">
                    <select
                        v-model="cityfilter"
                        @change="getGpss"
                    >
                        <option v-bind:value="0">City</option>
                        <option v-for="city in cities" v-bind:value="city">{{ city }}</option>
                    </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="filtrado">
                    <select
                        v-model="locationfilter"
                        @change="getGpss"
                    >
                        <option v-bind:value="0">Location</option>
                        <option v-for="location in locations" v-bind:value="location">{{ location }}</option>
                    </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="filtrado">
                    <select
                        v-model="inspectorfilter"
                        @change="getGpss"
                    >
                        <option v-bind:value="0">Inspect</option>
                        <option v-for="inspector in inspectors" v-bind:value="inspector">{{ inspector }}</option>
                    </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="filtrado">
                    <select
                        v-model="optypefilter"
                        @change="getGpss"
                    >
                        <option v-bind:value="0">Op Type</option>
                        <option v-for="optype in optypes" v-bind:value="optype">{{ optype }}</option>
                    </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="filtrado">
                    <select
                        v-model="datafilter"
                        @change="getGpss"
                    >
                        <option v-bind:value="0">Data</option>
                        <option v-for="datf in datasf" v-bind:value="datf">{{ datf }}</option>
                    </select>
                </div>
              </div>
          </div>
        </div>
    </div>
    <div class="datehide">
        <div class="row row-fluid justify-content-between align-items-center action-bar">
          <div class="col-md-8 d-flex align-items-start justify-content-start">
            </div>
          <div class="col-md-2">
            <input type="date" class="form control-form busqueda" placeholder="From" v-model="datefilterfrom" @change="getGpss">        
          </div>
          <div class="col-md-2">        
            <input type="date" class="form control-form busqueda" placeholder="To" v-model="datefilteruntil" @change="getGpss">
          </div>
        </div>
    </div>

    <div class="container contentwh">
        
        <div class="py-3 pl-6 pr-6">
            <div class="table-responsive">
                <table class="table table-borderless table-vertical-center listados">
                    <thead>
                        <tr>
                            <th>CUSTOMER:</th>
                            <th>SEAL NUMBER 1:</th>
                            <th>SEAL NUMBER 2:</th>
                            <th>INSPECTOR NAME:</th>
                            <th>GMT DATE&TIME:</th>
                            <th>COUNTRY:</th>
                            <th>CITY:</th>
                            <th>LOCATION:</th>
                            <th>INSPECTION TYPE:</th>
                            <th>RECEIVER:</th>
                            <th>TRUCK PLATE:</th>
                            <th>TRUCK ID:</th>
                            <th>CONTAINER BOOKING REF:</th>
                            <th>N UNITS:</th>
                            <th>UNIT 1:</th>
                            <th>UNIT 2:</th>
                            <th>ACTUAL:</th> 
                            <th>UNIT 1 BAGS:</th> 
                            <th>UNIT 2 BAGS:</th>      
                            <th>NR BAGS Reg:</th>                      
                            <th>(LABEL) BAG GROSS:</th>
                            <th>(LABEL) BAG TARE:</th>
                            <th>(LABEL) BAG NET:</th>         
                            <th>(WEIGHED) BAG GROSS:</th>                   
                            <th>(WEIGHED) BAG NET:</th>
                            <th>BAG WEIGHT DIFFERENCE:</th>
                            <th>(LABEL) UNIT GROSS:</th>
                            <th>(LABEL) UNIT NET:</th>                            
                            <th>(WEIGHED) UNIT GROSS:</th>
                            <th>(WEIGHED) UNIT NET:</th>
                            <th>UNIT WEIGHT DIFFERENCE:</th>
                            <th>(WEIGHED) TRUCK GROSS:</th>
                            <th>(WEIGHED) TRUCK TARE:</th>
                            <th>(WEIGHED) TRUCK NET:</th>
                            <th>TRUCK WEIGHT DIFFERENCE:</th>
                            <th>LOT NUMBER:</th>
                            <th>BAG NUMBER:</th>
                            <th>CLIENT SEAL QR:</th>
                            <th>CLIENT SEAL RECHECK:</th>
                            <th>3RD PARTY SEAL NR:</th>
                            <th>3RD PARTY PL MATCH:</th>
                            <th>3RD PARTY PACKING SEAL NR:</th>
                            <th>WEIGHT ISSUE:</th>
                            <th>RADIATION LEVEL:</th>
                            <th>COLOR OF STICHING THREAD:</th>
                            <th>BAG INSPECTED:</th>
                            <th>BAG CONDITION:</th>
                            <th>SEAL CONDITION:</th>
                            <th>CARGO CONDITION:</th>
                            <th>SEGREGATION:</th>
                            <th>RE-SEAL NR:</th>
                            <th>OP ID:</th>
                            <th>OBSERVATIONS:</th>      
                            <th>RADIATION INSPECTOR:</th>                                           
                            <th>WEIGHED:</th>                                                      
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="mb-3" v-for="gps in arrayGpss" :key="gps.id">                            
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.customer"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.seal_number_1"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.seal_number_2"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <router-link
                                    :to="{ path: '/gps/' + gps.id }"
                                    class="link-dash-gps"
                                >
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.user_name"
                                ></span>
                                </router-link>
                            </td>                            
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.date_gmt"
                                ></span>;<span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.time_gmt"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.country"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.city"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.location"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.inspection_type"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.receiver"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.truck_plate"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.truck_id"
                                ></span>
                            </td>                            
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-if="gps.cont_booking"
                                >{{gps.cont_booking}}</span>
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-else
                                >NO BOOKING</span>
                            </td>                            
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.n_units"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.trailler_1_plate"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.trailer_2_plate"
                                ></span>
                            </td>                           
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.actual"
                                ></span>
                            </td>                           
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-if="gps.unit_1_bags !=0" v-text="gps.unit_1_bags"
                                ></span>
                            </td>                           
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-if="gps.unit_2_bags !=0" v-text="gps.unit_2_bags"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.n_bags"
                                ></span>
                            </td>                          
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.kam_grossw"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.kam_bagw"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.kam_netw"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-if="gps.war_grossw"
                                >{{gps.war_grossw}}</span>
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-else
                                >NO WEIGHED</span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-if="gps.war_netw"
                                >{{gps.war_netw}}</span>
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-else
                                >NO WEIGHED</span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-if="gps.bag_w_diff"
                                >{{parseFloat(gps.bag_w_diff).toFixed(2)}}</span>
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-else
                                >NO WEIGHED</span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.label_unit_gross"
                                ></span>
                            </td>                               
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="parseFloat(gps.label_unit_net).toFixed(2)"
                                ></span>
                            </td>   
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-if="gps.weighed_tot_obj_gross"
                                >{{gps.weighed_tot_obj_gross}}</span>
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-else
                                >NO WEIGHED</span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-if="gps.weighed_tot_obj_net"
                                >{{parseFloat(gps.weighed_tot_obj_net).toFixed(2)}}</span>
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-else
                                >NO WEIGHED</span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-if="gps.unit_w_diff"
                                >{{parseFloat(gps.unit_w_diff).toFixed(2)}}</span>
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-else
                                >NO WEIGHED</span>
                            </td>                            
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-if="gps.gross"
                                >{{gps.gross}}</span>
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-else
                                >NO WEIGHED</span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-if="gps.tare"
                                >{{gps.tare}}</span>
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-else
                                >NO WEIGHED</span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-if="gps.net"
                                >{{gps.net}}</span>
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-else
                                >NO WEIGHED</span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"                                    
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.lot_number"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.bag_number"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.kam_seal_nr"
                                ></span>
                            </td> 
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.kam_seal_rechknr"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.thirdparty_seal"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.thirdparty_packing_match"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.third_p_seal"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.weight_issue_"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.radiation"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.color_stich_thr"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.inspected"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.bag_condition"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.seal_condition"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.cargo_condition"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.segregation"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.reseal_nr"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.id_charger"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.observations"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.radation_inspector"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="gps.weighing"
                                ></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>

    <pagination
            v-model="page"
            :records="total"
            :per-page="porpag"
            @paginate="paginar"
        />

  </div>

</template>

<script>    
    import axios from 'axios';
    import Pagination from "vue-pagination-2";
    import Swal from 'sweetalert2'
    
    export default {
        components: {
            Pagination,
        },
        data(){
            return{                
                arrayGpss:[], //Este array contendrá las tareas de nuestra bd                
                page: 1,
                total: 0,
                porpag: 20,
                searchtext:null,       
                customerfilter:null,
                lotnumbersearch: "",         
                countryfilter:0,
                countries:[],
                cityfilter:0,
                cities:[],
                locationfilter:0,
                locations:[],
                optypefilter:0,
                optypes:[],
                datafilter:"All",
                datasf:[                   
                    "Range",
                    "Today",
                    "All",
                    "Unknow"
                ],
                inspectorfilter:0,
                inspectors:[],
                idchargerfilter:0,
                datefilterfrom:null,
                datefilteruntil:null,         
                pagin:false,       
            }
        },
        methods:{
            paginar(){
                this.pagin=true;
                this.getGpss();
                this.pagin=false;
            },
            getGpss(data){                
                let me =this;
                let url = process.env.VUE_APP_BACK+'/api/gpss/'+me.page+'/'+me.porpag;
                //+'/'+me.countryfilter+'/'+me.cityfilter+'/'+me.locationfilter+'/'+me.optypefilter+'/'+me.datafilter+'/'+me.inspectorfilter;//+'/'+me.searchtext;                
                if(this.datafilter == "Range"){                    
                    $(".datehide").show();
                }
                else{
                    $(".datehide").hide();
                    this.datefilterfrom = null;
                    this.datefilteruntil = null;
                    if(this.datafilter == "Today"){
                        var today = new Date();
                        if(today.getMonth()+1<10)
                            var date = today.getFullYear()+'-0'+(today.getMonth()+1)+'-'+today.getDate();
                        else
                            var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                        this.datefilterfrom = date;
                        this.datefilteruntil = date;
                    }
                }
                if(this.searchtext=='' || this.searchtext == null){
                    this.searchtext=null;
                    axios.get(url, { params: {
                            countryfilter: this.countryfilter,
                            cityfilter: this.cityfilter,
                            locationfilter: this.locationfilter,
                            optypefilter: this.optypefilter,
                            datafilter: this.datafilter,
                            inspectorfilter: this.inspectorfilter,
                            idchargerfilter: this.idchargerfilter,
                            lotnumberfilter: this.lotnumbersearch,
                            datefilterfrom: this.datefilterfrom,
                            datefilteruntil: this.datefilteruntil,
                            customerfilter: this.customerfilter
                    }}).then(function (response) {
                        //console.log(response.data)
                        //me.total = response.data.total
                        me.total=response.data.total;
                        me.arrayGpss = response.data.data;                
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    });
                }
                else{
                    axios.get(url, { params: {
                            searchtext: this.searchtext,
                            countryfilter: this.countryfilter,
                            cityfilter: this.cityfilter,
                            locationfilter: this.locationfilter,
                            optypefilter: this.optypefilter,
                            datafilter: this.datafilter,
                            inspectorfilter: this.inspectorfilter,
                            idchargerfilter: this.idchargerfilter,
                            lotnumberfilter: this.lotnumbersearch,
                            datefilterfrom: this.datefilterfrom,
                            datefilteruntil: this.datefilteruntil,
                            customerfilter: this.customerfilter
                    }})
                    .then((response) => {                 
                        me.total=response.data.total;                                      
                        me.arrayGpss = response.data.data;  
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
                }
                if(this.pagin==false)
                {
                    this.page=1;
                }
            },
            getInspectors(data){
                let me =this;
                let url = process.env.VUE_APP_BACK+'/api/gpss/inspectors';                
                let inspectores = [];
                axios.get(url).then(function (response) {                    
                    //me.total = response.data.total
                    //console.log(response.data);
                    response.data.data.forEach(function(inspectordata, index) {
                        //console.log(inspectordata.user_name);
                        inspectores.push(inspectordata.user_name);
                    });
                    //console.log(inspectores);
                    me.total=response.data.total;
                    me.inspectors = inspectores;                
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                });
            },
            getInsTypes(data){
                let me =this;
                let url = process.env.VUE_APP_BACK+'/api/gpss/instypes';                
                let instypes = [];
                axios.get(url).then(function (response) {                    
                    //me.total = response.data.total
                    //console.log(response.data);
                    response.data.data.forEach(function(instypesdata, index) {
                        //console.log(inspectordata.user_name);
                        instypes.push(instypesdata.inspection_type);
                    });
                    //console.log(inspectores);
                    me.total=response.data.total;
                    me.optypes = instypes;                
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                });
            },
            getCountries(data){
                let me =this;
                let url = process.env.VUE_APP_BACK+'/api/gpss/countries';                
                let countries = [];
                axios.get(url).then(function (response) {
                    response.data.data.forEach(function(countrydata, index) {
                        countries.push(countrydata.country);
                    });
                    me.total=response.data.total;
                    me.countries = countries;                
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                });
            },
            getCities(data){
                let me =this;
                let url = process.env.VUE_APP_BACK+'/api/gpss/cities';                
                let cities = [];
                axios.get(url).then(function (response) {
                    response.data.data.forEach(function(citydata, index) {
                        cities.push(citydata.city);
                    });
                    me.total=response.data.total;
                    me.cities = cities;                
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                });
            },
            getLocations(data){
                let me =this;
                let url = process.env.VUE_APP_BACK+'/api/gpss/locations';                
                let locations = [];
                axios.get(url).then(function (response) {   
                    response.data.data.forEach(function(locationdata, index) {
                        locations.push(locationdata.location);
                    });
                    me.total=response.data.total;
                    me.locations = locations;                
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                });
            },
            async downloadItem(url, label) {
                  console.log(url);
                  //const response = await axios.get(url, { responseType: "blob" });
                  //const blob = new Blob([response.data], { type: "application/zip" });
                  const link = document.createElement("a");
                  link.href = url;
                  link.target ="_blank";
                  //link.download = label;
                  console.log(link);
                  link.click();
                  //URL.revokeObjectURL(link.href);
            },
            getCSVZip(){                
                let me =this;
                let url = process.env.VUE_APP_BACK+'/api/gpss/getzip';//+me.page+'/'+me.porpag+'/'+me.searchtext;
                axios.post(url, {
                        tipo: 0,
                        searchtext: this.searchtext,
                        countryfilter: this.countryfilter,
                        cityfilter: this.cityfilter,
                        locationfilter: this.locationfilter,
                        optypefilter: this.optypefilter,
                        datafilter: this.datafilter,
                        inspectorfilter: this.inspectorfilter,
                        idchargerfilter: this.idchargerfilter,
                        lotnumberfilter: this.lotnumbersearch,
                        datefilterfrom: this.datefilterfrom,
                        datefilteruntil: this.datefilteruntil,
                        customerfilter: this.customerfilter
                    }).then(function (response) {
                    //me.total = response.data.total
                    //me.arrayGpss = response.data;                
                    console.log(response.data.message);
                    //window.location.href = response.data.message;
                    /*Swal.fire({
                      title: 'Getting csv',
                      icon: 'info',
                      html: "<a target='_blank' href='"+process.env.VUE_APP_FRONT+'/'+response.data.message+"'>Download</a>",
                      showConfirmButton: false,
                    });*/
                    window.location.href = process.env.VUE_APP_FRONT+'/'+response.data.message;
                    //me.downloadItem(process.env.VUE_APP_FRONT+'/'+response.data.message,response.data.message);
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                });
            },
            getImagesZip(){
                let me =this;
                let url = process.env.VUE_APP_BACK+'/api/gpss/getzip';//+me.page+'/'+me.porpag+'/'+me.searchtext;
                console.log("searchtext", this.searchtext );
                console.log("countryfilter", this.countryfilter );
                console.log("cityfilter", this.cityfilter );
                console.log("locationfilter", this.locationfilter );
                console.log("optypefilter", this.optypefilter );
                console.log("datafilter", this.datafilter );
                console.log("inspectorfilter", this.inspectorfilter );
                console.log("idchargerfilter", this.idchargerfilter );
                console.log("datefilterfrom", this.datefilterfrom );
                console.log("datefilteruntil", this.datefilteruntil );
                console.log("customerfilter", this.customerfilter );
                axios.post(url, {
                        tipo: 1,
                        searchtext: this.searchtext,
                        countryfilter: this.countryfilter,
                        cityfilter: this.cityfilter,
                        locationfilter: this.locationfilter,
                        optypefilter: this.optypefilter,
                        datafilter: this.datafilter,
                        inspectorfilter: this.inspectorfilter,
                        idchargerfilter: this.idchargerfilter,
                        lotnumberfilter: this.lotnumbersearch,
                        datefilterfrom: this.datefilterfrom,
                        datefilteruntil: this.datefilteruntil,
                        customerfilter: this.customerfilter
                    }).then(function (response) {
                    //me.total = response.data.total
                    //me.arrayGpss = response.data;                
                    console.log(response.data.message);
                    //window.location.href = response.data.message;
                    /*Swal.fire({
                      title: 'Getting csv',
                      icon: 'info',
                      html: "<a target='_blank' href='"+process.env.VUE_APP_FRONT+'/'+response.data.message+"'>Download</a>",
                      showConfirmButton: false,
                    });*/
                    window.location.href = process.env.VUE_APP_FRONT+'/'+response.data.message;
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                });
            },
            getAllZip(){
                let me =this;
                let url = process.env.VUE_APP_BACK+'/api/gpss/getzip';//+me.page+'/'+me.porpag+'/'+me.searchtext;
                axios.post(url, {
                        tipo: 2,
                        searchtext: this.searchtext,
                        countryfilter: this.countryfilter,
                        cityfilter: this.cityfilter,
                        locationfilter: this.locationfilter,
                        optypefilter: this.optypefilter,
                        datafilter: this.datafilter,
                        inspectorfilter: this.inspectorfilter,
                        idchargerfilter: this.idchargerfilter,
                        lotnumberfilter: this.lotnumbersearch,
                        datefilterfrom: this.datefilterfrom,
                        datefilteruntil: this.datefilteruntil,
                        customerfilter: this.customerfilter
                    }).then(function (response) {
                    //me.total = response.data.total
                    //me.arrayGpss = response.data;                
                    console.log(response.data.message);
                    //window.location.href = response.data.message;
                    /*Swal.fire({
                      title: 'Getting csv',
                      icon: 'info',
                      html: "<a target='_blank' href='"+process.env.VUE_APP_FRONT+'/'+response.data.message+"'>Download</a>",
                      showConfirmButton: false,
                    });*/
                    window.location.href = process.env.VUE_APP_FRONT+'/'+response.data.message;
                })                    
                .catch(function (error) {
                    // handle error
                    console.error(error);
                });
            }
        },
        mounted() {            
           this.getInspectors();
           this.getInsTypes();
           this.getGpss();
           this.getCountries();
           this.getCities();
           this.getLocations();
        }
    }

</script>
