<template>
  <div class="fullheight">
    <div class="headerorange">
      <div class="logo-sgs"><img alt="Logo" src="/logo-sgs.png" width="200" /></div>
    </div>
    <div class="pantallalogin login">    
      <div class="formlogin">
        <form action class="form" @submit.prevent="recover">
          <h1 class="title">Forgotten Password?</h1>
          <p style="text-align:center">Enter your email to reset your password</p>
          <label class="form-label" for="#email">Your Email</label>
          <input
            v-model="email"
            class="form-input"
            type="email"
            id="email"
            required
            placeholder="Email"
          >
          <p v-if="error" class="error">Has been an error.</p>
          <p v-if="infodata" v-text="infodata" class="error"></p>
          <div class="form-label row-fluid row align-items-center justify-content-between">
              <div class="col-md-6">
                <input class="form-submit" type="submit" value="Submit">
              </div>
              <div class="col-md-6">
                <router-link to="/login" class="form-submit cancelbtn">Cancel</router-link>
              </div>
            </div>
        </form>
      </div>
    </div>
    <div class="footer-login justify-content-center">
        <div class="row-fluid row align-items-center justify-content-center">
            <div class="col-md-12">
                <p style="text-align:center">&copy; 2022 SGS | BAGS</p>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import auth from "@/logic/auth";
export default {
  data: () => ({
    email: "",
    password: "",
    infodata: null,
    error: false
  }),
  beforeCreate(){
      console.log("Deslogeado");
      auth.deleteUserLogged();
    },
  methods: {    
    async recover() {
      this.infodata = null;
      try {        
        let data = await auth.recover(this.email);
        const user = {
          email: this.email
        };
        //console.log(data.data.datos.msg);
        this.infodata = data.data.datos.msg;
        //console.log('EMAIL USER RECOVER PASS: '+user.email);
        //auth.setUserLogged(user.email);
        //this.$router.push("/login");
      } catch (error) {
        console.log(error);
        this.error = true;
      }
    }
  }
};
</script>

