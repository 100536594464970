import Vue from 'vue'
import VueRouter from 'vue-router'
//import HomeView from '../views/HomeView.vue'
import DashboardComponent from '../components/DashboardComponent.vue'
import UsersComponent from '../components/UsersComponent.vue'
import GpsComponent from '../components/GpsComponent.vue'
import LoginComponent from '../components/LoginComponent.vue'
import ContainerComponent from '../components/ContainerComponent.vue'
import RecoverComponent from '../components/RecoverComponent.vue'
import ResetComponent from '../components/ResetComponent.vue'
import UserComponent from '../components/UserComponent.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'container',
    component: ContainerComponent,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'users',
        name: 'users',
        component: UsersComponent 
      },
      {
        path: 'gps/:id',
        name: 'gps',
        component: GpsComponent 
      },
      {
        path: 'user/:id',
        name: 'user',
        component: UserComponent
      }
    ]
  },
  { 
    path: "/login", 
    name: 'login',
    components: {
      default: null,
      login: LoginComponent
    }
  },
  { 
    path: "/recover", 
    name: 'recover',
    components: {
      default: null,
      login: RecoverComponent
    }
  },
  { 
    path: "/reset/:hash", 
    name: 'reset',
    components: {
      default: null,
      login: ResetComponent
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
