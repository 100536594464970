    <template>

  <div>
    <div class="row row-fluid justify-content-between align-items-center action-bar">
      <div class="col-md-2">
        <router-link
            :to="{ path: '/users/'}"
            class="btn-back btnback"
                    ><
        </router-link>                    
      </div>
    </div>

    <div class="row row-fluid justify-content-between align-items-center action-bar">
      <div class="col-md-10">  
        &nbsp;
      </div>
      <div class="col-md-2 d-flex align-items-end justify-content-end">
        <div class="mostrardesplegable">
            <button @click="borrarUser" class="btn btn-primary btndash">Delete</button>            
        </div>
      </div>
    </div>

    <div class="container contentwh datatab">      

        <div class="py-3 pl-6 pr-6">            

            <div class="row row-fluid justify-content-between action-bar">
                <div class="col-md-12">                      
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-3 titledata">
                            Email:
                        </div>
                        <div class="col-md-9 contentdata">
                            {{arrayUsers.email}}
                        </div>
                    </div>
                </div>
                <div class="col-md-12">                   
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-3 titledata">
                            Rol:
                        </div>
                        <div class="col-md-9 contentdata">
                            <span
                                class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                v-if="arrayUsers.role==0"
                            >Admin</span>
                            <span
                                class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                v-if="arrayUsers.role==1"
                            >Origin</span>
                            <span
                                class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                v-if="arrayUsers.role==2"
                            >Destination</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">                   
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-3 titledata">
                            Validated:
                        </div>
                        <div class="col-md-9 contentdata">
                            <span
                                class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                v-if="arrayUsers.validated==0"
                            >No</span>
                            <span
                                class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                v-if="arrayUsers.validated==1"
                            >Yes</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

  </div>

</template>

<script>
    import axios from 'axios';
    import Vue from 'vue';
    import 'viewerjs/dist/viewer.css'
    import VueViewer from 'v-viewer'  
    Vue.use(VueViewer)

    export default { 
        data(){
            return{                
                arrayUsers:[], //Este array contendrá las tareas de nuestra bd  
                userId:null,
            }
        },
        methods:{
              show() {
                this.$viewerApi({
                  images: this.arrayImages,
                  options:{ 
                    "inline": true, 
                    "button": true,                 
                    "navbar": false, 
                    "title": false, 
                    "toolbar": false, 
                    "tooltip": true, 
                    "movable": false, 
                    "zoomable": true, 
                    "rotatable": false, 
                    "scalable": true, 
                    "transition": true, 
                    "fullscreen": true, 
                    "keyboard": false}
                })
              },
            getUser(data){                
                let me =this;
                let url = process.env.VUE_APP_BACK+'/api/users/'+me.userId;//+me.page+'/'+me.porpag+'/'+me.searchtext;
                axios.get(url).then(function (response) {
                    console.log(response.data);
                    me.arrayUsers = response.data;                
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                });
            },
            borrarUser(){                
                let me =this;
                let url = process.env.VUE_APP_BACK+'/api/users/'+me.userId;//+me.page+'/'+me.porpag+'/'+me.searchtext;
                axios
                    .put(url, {
                        user: me.userId,
                        borrado: true
                    })
                    .then((response) => {
                        //console.log(response.data.message);
                        this.$router.push('/users');
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
            }
        },
        mounted() {                 
           this.userId = this.$route.params.id;    
           this.getUser(this.$route.params.id);
        }
    }
</script>