<template>

<div>
	<div class="header-action" id="headerffix">
        <div class="header-logo">
            <div class="row row-fluid justify-content-between align-items-center">
                <div class="col-md-3">
                    <a :href="link_home">
                        <img alt="Logo" src="/logo-sgs.png" height="50px" />
                    </a>
                </div>
                <div class="col-md-6">&nbsp;</div>
                <div class="col-md-3 align-items-right d-flex justify-content-end">
                    <a class="logout" @click="logout">{{userLogged}} <b-icon-person-circle></b-icon-person-circle></a>
                    <!--router-link to="/logout" class="me-5">logout</router-link-->                    
                </div>
            </div>
        </div>
    </div>

    <div class="" style="background: white;">
        <div class="d-flex flex-column-fluid">
            <div class="container">
              <nav>
                <router-link to="/">Dashboard</router-link> |
                <router-link to="/users">Users</router-link>
              </nav>
            </div>
        </div>
    </div>

    <div class="contentmain">
        <div class="d-flex flex-column-fluid">
            <div class="container">
              <router-view></router-view>
            </div>
        </div>
    </div>

    <div class="footer-front">
        <div class="row-fluid row align-items-center">
            <div class="col-md-12">                
                <p>&copy; 2022 SGS | BAGS</p>
            </div>
        </div>
    </div>

</div>


</template>

<script>
import auth from "@/logic/auth";
export default {
        data(){
            return{                  
                link_home: process.env.VUE_APP_FRONT,
                logeado: null
            }
        },
      computed: {
        userLogged() {
            if(!auth.getUserLogged() && this.$route.name != 'login'){
                this.logeado = false;
                this.$router.push("/login"); 
                //console.log(this.$route.name); 
            }
            else{
                if(!auth.getUserLogged())
                    this.logeado = false;
                else{
                    this.logeado = true;
                    return auth.getUserLogged();
                }                
            }
        }
      },
      methods:{
        validarLogin() {
            if(!auth.getUserLogged() && this.$route.name != 'login'){
                this.logeado = false;
                this.$router.push("/login"); 
                console.log("logearse");
                //console.log(this.$route.name); 
            }
            else{
                if(!auth.getUserLogged()){
                    this.logeado = false;
                    console.log("logearse");
                }
                else{
                    this.logeado = true;
                    console.log("LOGADO");
                    return auth.getUserLogged();
                }                
            }
        },
        async logout(){
            await auth.deleteUserLogged();
            await this.validarLogin()
            //this.$router.push("/login"); 
        }
      },
      mounted(){
        this.validarLogin();
        console.log("test login")
      }  
}

</script>