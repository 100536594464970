import axios from "axios";
import Cookies from "js-cookie"; 

const ENDPOINT_PATH = process.env.VUE_APP_BACK+"/api/users/";
console.log(process.env)
export default {
  setUserLogged(userLogged) {
    Cookies.set("userLogged", userLogged);
  },
  getUserLogged() {
    //console.log(Cookies.get("userLogged"));
    return Cookies.get("userLogged");
  },
  deleteUserLogged() {
    //console.log("FUERA COOKIE");
    Cookies.remove('userLogged');
  },
  register(email, password) {
    const user = { email, password };
    return axios.post(ENDPOINT_PATH + "register", user);
  },
  recover(email, password) {
    const user = { email};
    return axios.post(ENDPOINT_PATH + "recover", user);
  },
  login(email, password) {
    const admin = true;
    const user = { email, password, admin };
    const logea = axios.post(ENDPOINT_PATH + "login", user);
    //console.log(logea);    
    //return logea;
    return logea
    .then(result => { console.log(result.data); return result.data; })
    .catch(error => { console.error(error); return Promise.reject(error); });
  }
};